import { useParams } from "react-router-dom";
import React, { useContext } from "react";

import "./ThankYou.css";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";



const ThankYou = () => {
  const { orderId } = useParams();
  const { openCartDrawer } = useContext(AppContext);
  const translation = useTranslation();
  return (
    <div className="container-thank-you">
      <div style={{lineHeight:"25px"}}>
        <div className="orderId_info">
          <div className="container-thank-you-circle">
            <div className="container-thank-you-tik"></div>
          </div>
          <div>
          <h2>{translation.t("order")}: <strong style={{textDecoration:"underline"}}>#{orderId}</strong></h2>
          <h2>{translation.t("order_confirmed")}</h2>
          </div>
        </div>

        <div>
          <a style={{
            color: " #777",
          }} href="/">
            &mdash; {translation.t("back")} homepage
          </a>
        </div>
      </div>
      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};
export default ThankYou;