import React, { useContext } from "react";
import "./terms.css";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { openCartDrawer } = useContext(AppContext) 
  const translation = useTranslation()
  return (
    <div>
      <div className="container-terms">
        <div className="content-terms">
        <h2>PRIVACY POLICY</h2>
          <p>
            <span>
              At Bluniblu, we are committed to protecting your privacy. This
              Privacy Policy outlines how we collect, use, and disclose personal
              information provided by users of our website.
              <br />
            </span>
            <br />
            <strong>1. Information We Collect</strong>
            <br />
            We may collect personal information such as your name, email
            address, shipping address, and payment details when you place an
            order on our website.
            <br />
            <br />
            <strong>2. How We Use Your Information</strong>
            <br />
            We use the information you provide to process your orders,
            communicate with you about your purchases, and improve our services.
            We do not share your personal information with third parties for
            marketing purposes.
            <br />
            <br />
            <strong>3. Your Choices</strong>
            <br />
            You have the right to access, update, or delete your personal
            information by contacting us. You may also opt out of receiving
            promotional emails from us.
            <br />
            <br />
            <strong>4. Children's Privacy</strong>
            <br />
            Our services are not directed to children under the age of 13. We do
            not knowingly collect personal information from children.
            <br />
            <br />
            <strong>5. Changes to this Policy</strong>
            <br />
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective immediately upon posting on our
            website.
            <br />
            <br />
            <strong>6. Contact Us</strong>
            <br />
            If you have any questions or concerns about our Privacy Policy or
            our practices, please contact us at{" "}
            <strong>privacy@bluniblu.com</strong>.
            <br />
            <br />
          </p>
        </div>
      </div>
      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};

export default Privacy;
