import React from 'react';
import './Loader.css';

function Loader() {
  return (
    // <div className='loader-all'>
      <div className="loader-box">
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
    </div>
    // </div>
  );
}

export default Loader;
