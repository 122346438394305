import React, { useContext } from "react";
import "./PageNotFound.css";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
    const { openCartDrawer } = useContext(AppContext)
    const translation = useTranslation();
  return (
    <div className="container-error-404">
      <div style={{lineHeight:"30px"}}>
        <div>
          <h2 className="error-header">Error 404. Page not found!</h2>
        </div>

        <div>
          <a className="back-homepage" href="/">
            &mdash; back to homepage
          </a>
        </div>
      </div>
      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};
export default PageNotFound;