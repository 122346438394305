import { Drawer } from "@mui/material";
import "./CartDrawer.css";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { CURRENCY_SYMBOL, S3_BUCKET_IMAGES_URL } from "../../utils/constants";
import Loader from "../../pages/loader/Loader";

// import { useNavigate } from "react-router-dom";

const CartItemsTitle = ({ translation }) => {
  return (
    <div>
      <h5>{translation.t("cart_items")}</h5>
    </div>
  );
};

const CartClose = ({ setOpenCartDrawer, translation }) => {
  return (
    <span
      className="cart-header-close"
      onClick={() => setOpenCartDrawer(false)}
    >
      <h5>{translation.t("close")}</h5>
    </span>
  );
};

const CartItems = ({
  selectedProducts = [],
  setSelectedProducts,
  translation,
}) => {
  const currentSelectedProducts =
    (localStorage.getItem("selectedProducts") &&
      JSON.parse(localStorage.getItem("selectedProducts") || [])) ||
    selectedProducts;

  // const navigate = useNavigate()


  const [loadingId, setLoadingId] = useState(false);

  return (
    <div>
      {(currentSelectedProducts || []).map((product) => (
        <div className="cart-item" key={product.list_id}>
          <img
            src={`${S3_BUCKET_IMAGES_URL}${product.image}`}
            alt={product.title}
            style={{
              mwidth: "6em",
              height: "6em",
            }}
          />
          <div style={{ width: "100%" }}>
            <div className="cart-item-details-drawer">
              <div className="cart-items-drawer">
              <h4><a href={`/shop/product/${product.title}`}>{product.title}</a></h4>
                <h4>{translation.t("size")}: {product.selectedSize}</h4>
                <div>
                <h4>
                  {CURRENCY_SYMBOL}
                  {product.price && product.price.split(CURRENCY_SYMBOL)[1]}
                </h4>
                <h5
                  onClick={() => {
                    setLoadingId(product.list_id);

                    setTimeout(() => {
                      setLoadingId(null);
                      
                      const filteredProducts = currentSelectedProducts.filter(
                        (p) =>
                          p.list_id !== product.list_id ||
                          p.selectedSize !== product.selectedSize
                      );

                      localStorage.setItem("selectedProducts", JSON.stringify(filteredProducts));
                      setSelectedProducts(filteredProducts);
                    }, 450);
                  }}
                >
                  {loadingId === product.list_id ? <Loader /> : <span className="remove-cart-item">{translation.t("remove")}</span>} 
                </h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const CartDrawer = ({ anchor, open, translation }) => {
  const { setOpenCartDrawer, selectedProducts, setSelectedProducts } =
    useContext(AppContext);
  const currentSelectedProducts = selectedProducts.length
    ? selectedProducts
    : localStorage.getItem("selectedProducts")
    ? JSON.parse(localStorage.getItem("selectedProducts"))
    : [];
  const prices =
    currentSelectedProducts.length &&
    currentSelectedProducts.map((p) => p.price.split(CURRENCY_SYMBOL)[1]);
  const subTotal =
    prices.length && prices.reduce((a, b) => Number(a) + Number(b), 0);

  return (
    <div>
      <Drawer
        classes={{
          paper: "cart-drawer",
        }}
        anchor={anchor}
        open={open}
        onClose={() => setOpenCartDrawer(false)}
      >
        <div className="cart-header">
          <CartItemsTitle translation={translation} />
          <CartClose
            setOpenCartDrawer={setOpenCartDrawer}
            translation={translation}
          />
        </div>
        <div>
          <CartItems
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            translation={translation}
          />
        </div>

        <div className="cart-drawer-sub_total">
        {!!subTotal && (
          <div>
            <div className="cart-subtotal">
            <h5>{translation.t("subtotal")} </h5>
            <h5>
              {CURRENCY_SYMBOL}{subTotal}
            </h5>
          </div>
          </div>
        )}

        {!!subTotal && (
          <div className="proceed-checkout-cart-drawer">
          <a href="/checkout" className="cart-checkout">
            {translation.t("checkout")}
          </a>
          </div>
        )}
        </div>
        {!subTotal && (
          <h5
          className="bag-is-empty-label"
          >
            {translation.t("your_cart_is_empty")}
          </h5>
        )}
      </Drawer>
    </div>
  );
};