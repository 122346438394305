import { useEffect, useState } from "react";
import { LIST_PRODUCTS_ENDPOINT } from "../utils/constants";

export const useProducts = () => {
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(LIST_PRODUCTS_ENDPOINT, {
        method: "GET",
      });
      const data = await response.json();
      setProductsList(
        data.sort((a, b) => parseInt(a.id) - parseInt(b.id)) || []
      );
      setIsLoading(false)
    };
    fetchProducts();
    
  }, []);
  
  return {
    productsList,
    isLoading,
    setProductsList,
  };
};
