const UserDetailsValidation = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  } else if (!/^[a-zA-Z]+$/.test(values.firstName)) {
    errors.firstName = "First name must have only alphabetic characters";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less";
  } else if (!/^[a-zA-Z]+$/.test(values.lastName)) {
    errors.lastName = "Last name must have only alphabetic characters";
  }

  if (!values.email) {
    errors.email = "Enter an email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.address) {
    errors.address = "Required";
  }

  if (!values.phoneNumber || !/^\+?[0-9()\s-]{6,15}$/.test(values.phoneNumber)) {
    errors.phoneNumber = !values.phoneNumber ? "Enter your phone number" : "Please include your country code, e.g. +44";
  }
  

  if (!values.city) {
    errors.city = "Required";
  } else if (!/^[a-zA-Z]+$/.test(values.city)) {
    errors.city = "City must have only alphabetic characters";
  }

  if (!values.country) {
    errors.country = "Select your country";
  }

  return errors;
};

export default UserDetailsValidation;
