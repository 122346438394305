import { useContext, useEffect, useMemo, useState, useRef } from "react";
import { AppContext } from "../../AppContext";
import "./Checkout.css";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  CURRENCY_SYMBOL,
  GET_PRODUCT_QUANTITY_LIST,
  S3_BUCKET_IMAGES_URL,
  SAVE_ORDER_ENDPOINT,
  UPDATE_STOCK_ENDPOINT,
} from "../../utils/constants";
import { generateRandomId } from "../../utils/util";
// import { useNavigate } from "react-router-dom";
// import { withRouter } from "react-router-dom";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { useFormik } from "formik";
import UserDetailsValidation from "../../form-validations/user-details";
import lodash from "lodash";
import Loader from "../../pages/loader/Loader";



const COUNTRIES = {
  KOSOVA: "KOSOVA",
  ALBANIA: "ALBANIA",
  NORTH_MACEDONIA: "NORTH_MACEDONIA",
};
const SHIPING_PRICES = {
  KOSOVA: 2,
  ALBANIA: 3,
  NORTH_MACEDONIA: 5,
};

const CheckoutForm = ({
  setCountry,
  setShippingPrice,
  setPostalCode,
  postalCode,
  formik,
}) => {
  return (
    <div className="checkout-form">
      <form className="checkout-form" onSubmit={(values) => {
        // console.log(values);
      }}>
        <div
        className="form-box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            variant="outlined"
            name="email"
            required
          />
          <ErrorMessageComponent formik={formik} field="email" />
        </div>

        <div className="checkout-form-row" style={{ width: "100%" }}>
        <div style={{position: "relative"}}>
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              name="firstName"
              sx={{
                display: "flex",
              }}
              // className="form-box"
              required
            />
            <ErrorMessageComponent formik={formik} field="firstName" />
          </div>
          <div style={{position: "relative"}}>
            <TextField
              id="outlined-basic"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              name="lastName"
              variant="outlined"
              sx={{
                display: "flex",
              }}
              // className="form-box"
              required
            />
            <ErrorMessageComponent formik={formik} field="lastName" />
          </div>
        </div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
  id="country"
  className="form-box"
  sx={{
    borderRadius: 0
  }}
  labelId="demo-simple-select-label"
  name="country"
  value={formik.values.country || ""} 
  label="Country"
  onChange={(event) => {
    formik.handleChange(event);
    setCountry(event.target.value);
    const shippingValue =
      (event.target.value === COUNTRIES.ALBANIA &&
        SHIPING_PRICES.ALBANIA) ||
      (event.target.value === COUNTRIES.KOSOVA &&
        SHIPING_PRICES.KOSOVA) ||
      (event.target.value === COUNTRIES.NORTH_MACEDONIA &&
        SHIPING_PRICES.NORTH_MACEDONIA);
    setShippingPrice(`${shippingValue}`);
  }}
  required
>
  <MenuItem value={COUNTRIES.ALBANIA}>Albania</MenuItem>
  <MenuItem value={COUNTRIES.KOSOVA}>Kosova</MenuItem>
  <MenuItem value={COUNTRIES.NORTH_MACEDONIA}>
    North Macedonia
  </MenuItem>
</Select>
<ErrorMessageComponent formik={formik} field="country" />
        </FormControl>
        <div
        className="form-box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Address"
            value={formik.values.address}
            onChange={formik.handleChange}
            name="address"
            variant="outlined"
            className="form-box"
            required
          />
          <ErrorMessageComponent formik={formik} field="address" />
        </div>

        {/* <TextField
          id="outlined-basic"
          label="Apartment, suite, etc"
          value={aparatament}
          onChange={(event) => setApartament(event.target.value)}
          variant="outlined"
          required
        /> */}
        <div className="checkout-form-row">
          <div
          className="form-box"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              id="outlined-basic"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              name="city"
              variant="outlined"
              sx={{
                display: "flex",
                flex: 1,
              }}
              className="form-box"
              required
            />
            <ErrorMessageComponent formik={formik} field="city" />
          </div>
          <TextField
            id="outlined-basic"
            label="Postal code"
            variant="outlined"
            value={postalCode}
            onChange={(event) => setPostalCode(event.target.value)}
            style={{ maxHeight: "50px" }}
            className="form-box"
          />
        </div>
        <div
        className="form-box"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Phone"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            variant="outlined"
            className="form-box"
            required
          />
          <ErrorMessageComponent formik={formik} field="phoneNumber" />
        </div>

        
      </form>

      <p className="form-warning">Before finalizing your order, please double-check all information provided to prevent processing delays.</p>
    </div>
  );
};

const ItemsList = ({
  selectedProducts = [],
  shippingPrice = 2,
  country,
  setOpenCartDrawer,
  openCartDrawer,
  setSelectedProducts,
  outOfStockMessage,
  setOutOfStockMessage,
  translation,
  completeOrderLoading,
  postalCode,
  setCompleteOrderLoading,
  formik,
  setUpdateProductList,
  isOverflowing,
  setIsOverflowing
}) => {
  const subtotal = subTotal(selectedProducts);
  // const shipping = getShippingPrice(country, shippingPrice);
  const shipping = country ? getShippingPrice(country, shippingPrice) : 0;

  // const navigate = useNavigate();
  // const history = withRouter();


  const message = useMemo(() => {
    return outOfStockMessage;
  }, [outOfStockMessage]);

  const renderShipping =
  formik.values.country.length < 1
    ? <p className="country_not_selected">{translation.t("select_country")}</p>
    : shipping === 0
    ? translation.t("free")
    : `${CURRENCY_SYMBOL}${shipping}`;
    
  const shippingTotal =
    shipping === 0
      ? Number(subtotal)
      : Number(subtotal) + Number(shippingPrice);

  useEffect(() => {
    setOpenCartDrawer(false);
  }, [setOpenCartDrawer]);

  const completeOrder = async ({
    email,
    firstName,
    lastName,
    address,
    // aparatament,
    country,
    postalCode,
    city,
    phoneNumber,
    shippingPriceByCountry,
    setSelectedProducts,
    setUpdateProductList,
  }) => {
    try {
      setCompleteOrderLoading(true);
      const sessionData = localStorage.getItem("selectedProducts")
        ? JSON.parse(localStorage.getItem("selectedProducts"))
        : [];

      const responseList = await fetch(GET_PRODUCT_QUANTITY_LIST, {
        method: "POST",
        body: JSON.stringify(sessionData),
        headers: {
          "content-type": "application/json",
        },
      });

      const dataList = await responseList.json();
      setUpdateProductList(dataList.data);
      const oneOfItemsIsOutOfStock = dataList.data.some((pr) => pr.out_of_stock);

      if (!oneOfItemsIsOutOfStock) {
        await updateStockAndSaveOrder({
          sessionData: dataList.data,
          shippingPriceByCountry,
          email,
          firstName,
          lastName,
          address,
          // aparatament,
          country,
          postalCode,
          city,
          phoneNumber,
          setUpdateProductList
        });
      }
      setCompleteOrderLoading(false);
    } catch (error) {
      console.log("Error: On Complete Order", error);
    }
  };

  const updateStockAndSaveOrder = async ({
    email,
    firstName,
    lastName,
    address,
    // aparatament,
    country,
    postalCode,
    city,
    phoneNumber,
    sessionData,
    shippingPriceByCountry,
    setUpdateProductList
  }) => {
    try {
      const orderId = `${generateRandomId()}_${email}`;
      const products = sessionData.map((s) => ({
        id: s.id,
        title: s.title,
        size: s.selectedSize,
        price: s.price,
        path: "/product-images/",
        image: s.image,
      }));
      const shippingPrice =
        country === COUNTRIES.KOSOVA
          ? 0
          : SHIPING_PRICES[shippingPriceByCountry];
      const orderDetails = {
        firstName,
        lastName,
        address,
        // aparatament,
        country,
        postalCode,
        city,
        phoneNumber,
        products: products,
        shippingPrice,
        subTotal: subTotal(sessionData),
        total: subTotal(sessionData) + shippingPrice,
      };

      const sizesOfCartProducts = sessionData.map((product) => ({
        id: product.id,
        title: product.title,
        selectedSize: product.selectedSize,
        quantity: product.quantity,
      }));

      const groupByTitle = lodash.groupBy(
        sizesOfCartProducts,
        ({ title }) => title
      );

      let itemsToUpdate = [];

      Object.entries(groupByTitle).forEach(([key, productsList]) => {
        const id = products.find(pr => pr.title === key).id;
        const sItems = productsList.filter((p) => p.selectedSize === "S").length;
        const mItems = productsList.filter(
          (p) => p.selectedSize === "M"
        ).length;
        const lItems = productsList.filter(
          (p) => p.selectedSize === "L"
        ).length;
        
        const xlItems = productsList.filter(
          (p) => p.selectedSize === "XL"
        ).length;

        itemsToUpdate.push({
          id,
          title: key,
          quantity: {
            S: Math.max(0, productsList[0].quantity.S - sItems),
            M: Math.max(0, productsList[0].quantity.M - mItems),
            L: Math.max(0, productsList[0].quantity.L - lItems),
            XL: Math.max(0, productsList[0].quantity.XL - xlItems),
          },
        });
      });

      const updateStock = async (item) => {
        try {
          const response = await fetch(UPDATE_STOCK_ENDPOINT, {
            method: "POST",
            body: JSON.stringify(item),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to update stock for ${item.title}`);
          }
        } catch (error) {
          console.error(error.message);
        }
      };

      const updateStockPromises = itemsToUpdate.map(updateStock);

      await fetch(SAVE_ORDER_ENDPOINT, {
        method: "POST",
        body: JSON.stringify({
          id: orderId,
          email,
          orderDetails,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      setCompleteOrderLoading(false);

      localStorage.setItem("selectedProducts", "[]");
      setUpdateProductList([]);

      // navigate(`/thank-you/${orderId.split("_")[0]}`);
      // history.push(`/thank-you/${orderId.split("_")[0]}`);
      window.location.href = `/thank-you/${orderId.split("_")[0]}`;

      try {
        await Promise.all(updateStockPromises);
      } catch (error) {
        console.error("Error updating stock:", error.message);
      }
    } catch (error) {
      console.log("error while completing order!", error);
    }
  };
  const [isOrderSummaryActive, setOrderSummaryActive] = useState(true);

  const toggleDropdown = () => {
    setOrderSummaryActive(!isOrderSummaryActive);
  };

 

  // check if its overflowing
  const scrollCartItems = useRef(null);
  
  
  // const handleOverflowCheck = () => {
  //   setTimeout(() => {
  //     if (scrollCartItems.current) {
  //       const overflowing = scrollCartItems.current.scrollHeight > scrollCartItems.current.clientHeight;
  //       setIsOverflowing(overflowing);
  //     }
  //   }, 200); 
  // };

  // const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // disable overflow
      if (scrollCartItems.current) {
        if(selectedProducts.length >= 4) {
          setIsOverflowing(true)
        } else {
          setIsOverflowing(false)
        }


        // if(selectedProducts.length >= 5){
        // setIsOverflowing(true);
        // } else if(selectedProducts.length < 5){
        //   console.log("false");
        //   setIsOverflowing(false);
        // }

      } 
  }, [selectedProducts, selectedProducts.length, setIsOverflowing]);

  
  
  
  // check if its overflowing

  // handle scroll for more label
  const [completeOrderError, setCompleteOrderError] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [loadingId, setLoadingId] = useState(false);
  const [isPaymentMethodChecked, setIsPaymentMethodChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsPaymentMethodChecked(!isPaymentMethodChecked);
  };



  // const handleScroll = () => {
  //   if (!scrolled) {
  //     const targetElement = document.getElementsByClassName('scroll-down-label')[0];
  //     targetElement.classList.add('hide_scroll');
  //     setScrolled(true); // Set scrolled to true after the first scroll
  //   }
  // };

  useEffect(() => {
    function handleFirstScroll() {
      setScrolled(true);
      const checkoutWrapper = document.querySelector('.checkout-wrapper');
      checkoutWrapper.removeEventListener('scroll', handleFirstScroll);
    }

    const checkoutWrapper = document.querySelector('.checkout-wrapper');
    checkoutWrapper.addEventListener('scroll', handleFirstScroll);

    return () => {
      checkoutWrapper.removeEventListener('scroll', handleFirstScroll);
    };
  }, []); 

  // handle scroll for more label

  return (
    <div className="container">
 
      

      {/* <div className={`show-order-summary ${isActive ? 'orderSummaryActive' : ''}`}></div> */}
        <div className="show-order-summary-toggle" onClick={toggleDropdown}>
          <div>

      <p>{isOrderSummaryActive ? translation.t("hide") : translation.t("show")} {translation.t("order_summary")}</p>

      <svg fill="#08f" width="10px" className={`arrDown-checkout arrowDown_checkout ${isOrderSummaryActive ? 'flip_arrow' : ''} '`}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.04 19.24"><path d="M0 .02C.15.01.24 0 .33 0H4.3c.22 0 .35.07.48.25 3.7 4.93 7.41 9.85 11.12 14.78.09.12.19.25.3.35.24.23.43.23.67 0 .1-.1.19-.21.28-.33 3.37-4.47 6.74-8.95 10.1-13.42.36-.48.72-.95 1.07-1.43.09-.13.19-.19.35-.19h4.15c.06 0 .12.01.21.02-.06.09-.09.16-.14.22-4.31 5.73-8.62 11.46-12.94 17.18-.62.82-1.41 1.41-2.41 1.67-1.45.37-2.72 0-3.82-.97-.27-.23-.5-.51-.72-.8C8.99 11.99 4.98 6.66.96 1.32.69.96.41.59.13.22.09.17.06.12 0 .02Z"/></svg>
          </div>


      {/* <span className="minimize" style={{display: isOrderSummaryActive ? 'block' : 'none'}}>&mdash;</span> */}
      <span>{CURRENCY_SYMBOL}{shippingTotal}</span>
        </div>
      <div className="scroll-cart-items-box">
      <div id="dropdownContent" className={`show-order-summary ${isOrderSummaryActive ? 'orderSummaryActive' : ''}`}>
      <div ref={scrollCartItems} className="checkout-wrapper scroll-cart-items" style={{overflow: selectedProducts.length >= 4 ? "auto" : ""}}>
        {selectedProducts.map((product) => {
          if (product.outOfStock || product.out_of_stock) {
            setOutOfStockMessage(translation.t("card_update_message"));
          }

          return (
            <div className="checkout-cart-item" key={product.list_id}>
              <img
                src={`${S3_BUCKET_IMAGES_URL}${product.image}`}
                alt="Product"
                style={{
                  width: "4.6em",
                  height: "4.6em",
                }}
              />
              <div className="cart">
                <div
                  className="cart-item-details"
                  style={{
                    opacity: product.out_of_stock ? ".6" : "1",
                  }}
                >
                  <div className="checkout-row checkout-cart-product-title">
                    <div className="checkout-cart-items-inner">
                      <div className="cart-items-checkout"

                      >
                        <a href={`/shop/product/${product.title}`}>{product.title}</a>
                        <p>
                          {CURRENCY_SYMBOL}
                          {product.price.split(CURRENCY_SYMBOL)[1]}
                        </p>
                      </div>

                      <div className="cart-items-checkout">
                        <p>
                          {translation.t("size")}: {product.selectedSize}
                        </p>

                        <div
                          className="remove-button-checkout"
                          style={{ position: "relative" }}
                        >
                         <h5
                            onClick={() => {
                              setLoadingId({
                                id: product.list_id,
                                loading: true
                              } );
                              setTimeout(() => {
                                setLoadingId({
                                  id: null,
                                  loading: false
                                });

                                const filteredProducts = selectedProducts.filter(
                                  (p) =>
                                    p.list_id !== product.list_id ||
                                    p.selectedSize !== product.selectedSize
                                );
                                localStorage.setItem("selectedProducts", JSON.stringify(filteredProducts));
                                setSelectedProducts(filteredProducts);
                                setUpdateProductList(filteredProducts);
                                // handleOverflowCheck();
                                // handleScroll();
                              }, 450);
                            }}
                          >
                            {loadingId.id === product.list_id ? <Loader /> : <span className="remove-cart-item">{translation.t("remove")}</span>} 
                          </h5>


                        </div>
                        {product.out_of_stock && (
                          <span className="out-of-stock-label">
                            {translation.t("out_of_stock")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br
                style={{
                  width: "90%",
                }}
              />
            </div>
          );
        })}
        <div className={`scroll-down-label ${isOverflowing ? '' : 'hide_scroll'} ${scrolled ? 'hide_scroll' : ''}`}>
          <div>
          <span>Scroll for more items</span>
          <span className="scroll-down-icon"></span>
          </div>
        </div>
      </div>
      </div>
    </div>
      <div className="checkout-totals">
        <div className="checkout-row">
          <p>{translation.t("subtotal")}</p>
          <p>
            {CURRENCY_SYMBOL}
            {subtotal}
          </p>
        </div>
        <div className="checkout-row">
          <p>{translation.t("shipping")}</p>
          <p>{renderShipping}</p>
        </div>

        <div className="checkout-row checkout-total">
        <h4>{translation.t("total")}</h4>
        <h4>
          {CURRENCY_SYMBOL}
          {shippingTotal}
        </h4>
      </div>
      
      <span className="diamond">&mdash;&mdash;</span>

        <div className="payment-method-container">
        {/* {!isPaymentMethodChecked ? <p className="payment-method-error">{translation.t("select_payment_method")}</p> : ""} */}
        <p
  className="payment-method-error"
  style={{
    color: isPaymentMethodChecked ? "white" : completeOrderError ? "white" : "black",
    backgroundColor: isPaymentMethodChecked ? "#0b3" : completeOrderError ? "red" : ""
  }}
>
  {translation.t("select_payment_method")}
</p>

        {/* ===checkbox */}
        <div className="checkbox-wrapper-30 payment-method-options">
          <p>{translation.t("cod")}</p>
      <span className="checkbox">
        <input type="checkbox" checked={isPaymentMethodChecked} onChange={handleCheckboxChange}/>
        <svg>
          <use xlinkHref="#checkbox-30" className="checkbox"></use>
        </svg>
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="checkbox-30" viewBox="0 0 22 22">
          <path
            fill="none"
            stroke="currentColor"
            d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
          />
        </symbol>
      </svg>
    </div>
        {/* ===checkbox end */}


        <div className="coming-soon-payments payment-method-options">
          <h4>Coming Soon:</h4>
          <img src="/images/tbd-payments.webp" alt="tbd payment methods"/>
        </div>
        </div>

        
      </div>

      
      <form
        onSubmit={async (values) => {
          formik.handleSubmit(values);
          if (
            !Object.keys(formik.errors).length &&
            Object.entries(formik.values).every(([_, prop]) => prop) &&
            isPaymentMethodChecked
          ) {
            await completeOrder({
              email: formik.values.email,
              firstName: formik.values.firstName,
              lastName: formik.values.lastName,
              address: formik.values.address,
              phoneNumber: formik.values.phoneNumber,
              postalCode,
              city: formik.values.city,
              country: country,
              shippingPriceByCountry: country,
              setSelectedProducts,
              outOfStockMessage,
              setUpdateProductList,
            });
          } else if(Object.keys(formik.errors).length) {
              document.querySelector('.main_content').scrollIntoView({ behavior: 'smooth' });
          } else if(Object.keys(formik.errors).length) {
            document.querySelector('.main_content').scrollIntoView({ behavior: 'smooth' });
          } else if(!isPaymentMethodChecked) {
          setCompleteOrderError(true);
        }

      }} 
        className="checkout-form-button-box"
      >
        <Button
          variant="contained"
          type="submit"
          disabled={loadingId.loading || completeOrderLoading || !selectedProducts.length}
          className="checkout-form-button"
          
        >
          {/* {completeOrderLoading && <i className="fa-solid fa-spinner fa-spin"></i>} */}
          {completeOrderLoading ? <Loader /> : translation.t("complete_order")}
        </Button>

        <h4 style={{ color: "#de0000", fontWeight: "400", fontSize:"13px" }}>{message}</h4>
      </form>

      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};

const subTotal = (selectedProducts = []) => {
  const prices = selectedProducts.map(
    (p) => p && p.price && p.price.split(CURRENCY_SYMBOL)[1]
  );
  const subTotal =
    prices.length && prices.reduce((a, b) => Number(a) + Number(b), 0);

  return subTotal;
};

const getShippingPrice = (selectedCountry, shippingPrice) => {
  if (selectedCountry === COUNTRIES.KOSOVA) {
    return 0;
  }

  return shippingPrice;
};

const ErrorMessageComponent = ({ formik, field }) =>
  formik.errors[field] ? (
    <div className="required_label">{formik.errors[field]}</div>
  ) : null;

export const Checkout = ({ translation }) => {
  const {
    setSelectedProducts,
    selectedProducts,
    setOpenCartDrawer,
    openCartDrawer,
    outOfStockMessage,
    setOutOfStockMessage,
    isOverflowing,
    setIsOverflowing
  } = useContext(AppContext);

  // const [country, setCountry] = useState(COUNTRIES.KOSOVA);
  const [country, setCountry] = useState(null);
  const sessionData = localStorage.getItem("selectedProducts")
    ? JSON.parse(localStorage.getItem("selectedProducts"))
    : [];

  const currentSelectedProducts = selectedProducts.length
    ? selectedProducts
    : sessionData;

  const [updatedProductsList, setUpdateProductList] = useState(
    currentSelectedProducts || []
  );

  const [shippingPrice, setShippingPrice] = useState(2); // for kosova
  const [completeOrderLoading, setCompleteOrderLoading] = useState(false);
  const [postalCode, setPostalCode] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      phoneNumber: "",
      city: "",
      country: ""
    },
    validate: UserDetailsValidation,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    setUpdateProductList(currentSelectedProducts)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts])

  return (
    <div className="checkout_container">
      <div className="checkout">
        <h4 className="checkoutHeader">{translation.t("checkout_title")}</h4>
        <div className="checkout-main">
          <CheckoutForm
            setCountry={setCountry}
            country={country}
            setShippingPrice={setShippingPrice}
            setSelectedProducts={setSelectedProducts}
            setOutOfStockMessage={setOutOfStockMessage}
            outOfStockMessage={outOfStockMessage}
            selectedProducts={currentSelectedProducts}
            translation={translation}
            setCompleteOrderLoading={setCompleteOrderLoading}
            setPostalCode={setPostalCode}
            postalCode={postalCode}
            formik={formik}
          />
          <ItemsList
            selectedProducts={updatedProductsList}
            shippingPrice={shippingPrice}
            country={country}
            setOpenCartDrawer={setOpenCartDrawer}
            openCartDrawer={openCartDrawer}
            setSelectedProducts={setSelectedProducts}
            outOfStockMessage={outOfStockMessage}
            setOutOfStockMessage={setOutOfStockMessage}
            translation={translation}
            setCompleteOrderLoading={setCompleteOrderLoading}
            completeOrderLoading={completeOrderLoading}
            postalCode={postalCode}
            formik={formik}
            setUpdateProductList={setUpdateProductList}
            isOverflowing={isOverflowing}
            setIsOverflowing={setIsOverflowing}
          />
        </div>
      </div>
    </div>
  );
};