import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createContext } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./translations/i18n"
export const ProductContext = createContext("");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);

reportWebVitals();
