export const MAIN_LAMBDA_ENDPOINT =
  "https://09jkeyaoa8.execute-api.eu-central-1.amazonaws.com/dev";
export const LIST_PRODUCTS_ENDPOINT = `${MAIN_LAMBDA_ENDPOINT}/list-products`;
export const UPDATE_STOCK_ENDPOINT = `${MAIN_LAMBDA_ENDPOINT}/update-stock`;
export const SAVE_ORDER_ENDPOINT = `${MAIN_LAMBDA_ENDPOINT}/save-order`;
export const GET_PRODUCT_DETAILS = `${MAIN_LAMBDA_ENDPOINT}/get-product-details`;
export const GET_PRODUCT_QUANTITY_LIST = `${MAIN_LAMBDA_ENDPOINT}/get-product-quantity-by-list`;
export const S3_BUCKET_IMAGES_URL =
  "https://bluniblu-product-images.s3.eu-central-1.amazonaws.com";
export const CURRENCY_SYMBOL = "€";
