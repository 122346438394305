import { useContext } from "react";
import "./index.css";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { AppContext } from "../../AppContext";

const Home = ({translation}) => {

  const { openCartDrawer } = useContext(AppContext);

  return (
    <div className="homepage-container">
  <div className="background-image"></div>

        <a href="/shop" className="enter_button">
        <button>{translation.t("enter")}</button>
        </a>

      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};

export default Home;
