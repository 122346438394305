/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./App.css";
import "./Footer.css";
import "./Header.css";
import ProductGrid from "./ProductGrid";
import ProductDetail from "./ProductDetail";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import { AppContext } from "./AppContext";
import { Checkout } from "./pages/Checkout/Checkout";
import ThankYou from "./pages/thank-you/ThankYou";
import OrderDetails from "./pages/order-details";
import { useTranslation } from "react-i18next";
import CartIcon from "./assets/CartIcon";
import Home from "./pages/Home";
import Terms from "./pages/footer-pages/terms";
import Privacy from "./pages/footer-pages/privacy";
import Returns from "./pages/footer-pages/returns";
import PageNotFound from "./pages/errors/PageNotFound";

function App() {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [outOfStockMessage, setOutOfStockMessage] = useState("");
  const [openCartDrawer, setOpenCartDrawer] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);


  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        const storedSelectedProducts = JSON.parse(localStorage.getItem("selectedProducts")) || [];
        setSelectedProducts(storedSelectedProducts);

        document.querySelector('.cart-items-number').innerHTML = storedSelectedProducts.length.toString();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);




  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  // const getPath = () => {
  //   if(window.location.pathname === "/") return "home";
  // }

  const homeFooterStyles = {
    position: 'fixed',
    bottom: '25px',
    background: 'none',
    filter: 'invert(1)',
    border: 'none',
    marginTop: '0'
  };
  
  const checkoutStyles = {
    marginTop: "-28px"
  };

  let finalFooterStyles = {};
if (window.location.pathname === '/') {
  finalFooterStyles = homeFooterStyles;
} else if (window.location.pathname === '/checkout' || window.location.pathname.startsWith('/thank-you')) {
  finalFooterStyles = checkoutStyles;
}


  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "");
  const translation = useTranslation();
  return (
    <Router>
      <AppContext.Provider
        value={{
          selectedProducts,
          openCartDrawer,
          setSelectedProducts,
          setOpenCartDrawer,
          outOfStockMessage,
          setOutOfStockMessage,
          setIsOverflowing,
          isOverflowing
        }}
      >
       <header>
       <div
          className="header"
        >
          <div className="menu-icons">
          <button
            onClick={toggleMenu}
            className={`openIcon ${isMenuOpen ? "open-button_active" : ""}`}
          >
          </button>
          <span
            className={`closeIcon ${isMenuOpen ? "close-button_active" : ""}`}
            onClick={toggleMenu}
          >
          </span>
          </div>

          <h1 title="BLUNIBLU | HOMEPAGE">
            <a href="/" className="logo-box">
              <img
                src={process.env.PUBLIC_URL + "/images/bluniblu.svg"}
                alt="BLUNIBLU"
              />
            </a>
          </h1>

          {
            <div
              className="nav-cart"
              onClick={() => {
                setOpenCartDrawer(true);
                if (isMenuOpen) {
                  toggleMenu();
                }
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <span>
              <CartIcon />
              

      <p className="cart-items-number">
            {selectedProducts.length ||
              (JSON.parse(localStorage.getItem("selectedProducts")) || [])
                .length}
          </p>

              </span>
            </div>
          }
        </div>
       </header>

        <div className={`nav-menu ${isMenuOpen ? "nav-menu-active" : "nav-menu_inactive"}`}>
          <nav className="nav-menu-ul">
            <ul
              className={`${
                isMenuOpen ? "nav-menu-list_active" : "nav-menu-list_inactive"
              }`}
            >
              <li>
                <a href="/shop">SHOP</a>
              </li>
              <br />
              <li>
                <a href="/terms">{translation.t("terms")}</a>
              </li>
              <li>
                <a href="/privacy">{translation.t("privacy")}</a>
              </li>
              <li>
                <a href="/returns">{translation.t("returns")}</a>
              </li>
              
              <br />
              <li>
                <span className="language-select-box_footer">
                  <label htmlFor="choose-language-footer">LANGUAGE:</label>

                  <select
                    className="language-select-footer language_header"
                    onChange={(e) => {
                      i18n.changeLanguage(e.target.value);
                      setLanguage(e.target.value);
                    }}
                    defaultValue={language}
                  >
                    <option value="" disabled defaultValue>
                      CHOOSE LANGUAGE
                    </option>
                    <option value="en">ENGLISH</option>
                    <option value="al">SHQIP</option>
                  </select>
                </span>
              </li>
            </ul>
          </nav>
        </div>

        <div className="App">
          <section className="main_content">
            <Routes>
              <Route path="/" element={<Home translation={translation} />} />
              <Route
                path="/shop"
                element={<ProductGrid translation={translation} />}
              />
              <Route
                path="/shop/product/:title"
                element={<ProductDetail translation={translation} />}
              />

              <Route
                path="/checkout"
                element={<Checkout translation={translation} />}
              />
              <Route
                path="/thank-you/:orderId"
                element={<ThankYou translation={translation} />}
              />
              <Route
                path="/order-details/:orderId"
                element={<OrderDetails translation={translation} />}
              />

              {/* footer paths */}
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/returns" element={<Returns />} />
              {/* footer paths end*/}


              {/* <Route path="*" element={<PageNotFound />} /> */}

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </section>
        </div>

        <footer className="footer" style={finalFooterStyles}>


          <ul className="footer_ul">
            <li>
              <a href="/terms">{translation.t("terms")}</a>
            </li>
            <li>
              <a href="/privacy">{translation.t("privacy")}</a>
            </li>
            <li>
              <a href="/returns">{translation.t("returns")}</a>
            </li>
           
            <li>
              <a
                href="https://instagram.com/bluniblu"
                target="_blank"
                rel="noreferrer"
              >
                INSTAGRAM
              </a>
            </li>
            <li>
              <div className="language-select-box_footer">
                <label
                  htmlFor="choose-language-footer"
                >
                  LANGUAGE:
                </label>

                <select
                  className="language-select-footer"
                  onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                    setLanguage(e.target.value);
                  }}
                  defaultValue={language}
                >
                  <option value="" disabled defaultValue>
                    CHOOSE LANGUAGE
                  </option>
                  <option value="en">ENGLISH</option>
                  <option value="al">SHQIP</option>
                </select>
              </div>
            </li>
            <li>&copy; 2024 BLUNIBLU.COM </li>
          </ul>
        </footer>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
