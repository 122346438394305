import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import products from "./productsData";
import "./ProductDetail.css";
import { AppContext } from "./AppContext";
import { CartDrawer } from "./components/CartDrawer/CartDrawer";
import { useProducts } from "./hooks/useProducts";
import { generateRandomId } from "./utils/util";
import { S3_BUCKET_IMAGES_URL } from "./utils/constants";
import Loader from "./pages/loader/Loader";


const ProductDetail = ({ translation }) => {
  const { productsList: products, isLoading } = useProducts();

  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const { title } = useParams();
  const [productsList, setProductsList] = useState(products);
  const product = products.find((p) => p.title === title);

  const [selectedSize, setSelectedSize] = useState("");
  // const [selectedUnit, setSelectedUnit] = useState("inches"); // Default selected unit
  // const [selectedMenuItem, setSelectedMenuItem] = useState("Details"); // Default selected menu item
  // set vars
  const [sizeError, setSizeError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const [animationTriggered, setAnimationTriggered] = useState(false);

  const [isProductDescriptionVisible, setIsProductDescriptionVisible] = useState(false);
  const toggleProductDescription = () => {
    setIsProductDescriptionVisible(!isProductDescriptionVisible);
    if(isSizeGuideVisible) toggleSizeGuide();

  };

  const [isSizeGuideVisible, setIsSizeGuideVisible] = useState(false);
  const toggleSizeGuide = () => {
    setIsSizeGuideVisible(!isSizeGuideVisible);
    if(isProductDescriptionVisible) toggleProductDescription();
  };



  

  const {
    selectedProducts,
    setSelectedProducts,
    setOpenCartDrawer,
    openCartDrawer,
  } = useContext(AppContext);
  

  // useEffect(() => {}, []);

  useEffect(() => {
    setOpenCartDrawer(false);
  }, [setOpenCartDrawer]);

  useEffect(() => {
    if (openCartDrawer) {
      setSizeError(false);
      setQuantityError(false);
      setAddedToCart(false);
    }
  }, [openCartDrawer]);

  // useEffect(() => {
  //   if (product && !isLoading) {
  //     const currentProduct = selectedProducts.find(
  //       (cp) => cp.title === product.title
  //     );
  //   }
  // }, [isLoading, product, selectedProducts]);

  // const handleMenuItemChange = (menuItem) => {
  //   setSelectedMenuItem(menuItem);
  // };

  // const handleUnitChange = (unit) => {
  //   setSelectedUnit(unit);
  // };

  if (!product) {
    // return <div>{<PageNotFound />}</div>;
    return <div className="loader-container">{<Loader />}</div>;
  }

  const cartAddedProducts =  localStorage.getItem("selectedProducts")
      ? JSON.parse(localStorage.getItem("selectedProducts"))
      : selectedProducts;
    const currentProductTitle = decodeURI(window.location.pathname).split("/shop/product/")[1]
    const productsBySize = cartAddedProducts.filter(pr => pr.selectedSize === selectedSize && pr.title === currentProductTitle)
    const noMoreStockForItem = productsBySize.length === product["quantity"][selectedSize] && product.title === currentProductTitle

  const handleAddToCart = () => {
    if(noMoreStockForItem){
      setQuantityError(true);
      setAddedToCart(false);
      return
    } else if(!noMoreStockForItem){
      setQuantityError(false);
    }
    if(!selectedSize){
      setSizeError(true); 
    } else{
      setAnimationKey(animationKey + 1);
      setAnimationTriggered(true);
      // setOpenCartDrawer(true);
      setAddedToCart(true); 
    const currentProducts = localStorage.getItem("selectedProducts")
      ? JSON.parse(localStorage.getItem("selectedProducts"))
      : selectedProducts;
    localStorage.setItem(
      "selectedProducts",
      JSON.stringify([
        ...currentProducts,
        {
          ...product,
          id: product.id,
          selectedSize: selectedSize || "S",
          list_id: generateRandomId(),
        },
      ])
    );
    setSelectedProducts([
      ...currentProducts,
      {
        ...product,
        id: product.id,
        selectedSize: selectedSize || "S",
        list_id: generateRandomId(),
      },
    ]);

    const updatedQuantity = productsList.map((pr) => {
      if (pr.title === product.title) {
        const currentQuantity = pr.quantity[`quantity${selectedSize}`];
        return {
          ...pr,
          quantity: {
            ...pr.quantity,
            [`quantity${selectedSize}`]:
              currentQuantity > 0 ? currentQuantity - 1 : 0,
          },
        };
      }
      return pr;
    });
    setProductsList(updatedQuantity);
    }
  };

  if (isLoading) {
    return <div>Loading..</div>;
  }

  // const handleAddToCartDisabled = (product, selectedSize) => {
    // const cartAddedProducts =  localStorage.getItem("selectedProducts")
    //   ? JSON.parse(localStorage.getItem("selectedProducts"))
    //   : selectedProducts;
    // const currentProductTitle = decodeURI(window.location.pathname).split("/shop/product/")[1]
    // const productsBySize = cartAddedProducts.filter(pr => pr.selectedSize === selectedSize && pr.title === currentProductTitle)
    // const noMoreStockForItem = productsBySize.length === product["quantity"][selectedSize] && product.title === currentProductTitle


    // const disabled =
    //   product.quantity[`${selectedSize.toUpperCase()}`] === 0 ||
    //   // !selectedSize ||
    //   noMoreStockForItem;

    // return disabled
  // };

  const disabledSize = (size) => {
    return (
      product["quantity"][size] === 0 &&
      decodeURI(window.location.pathname).split("/shop/product/")[1] ===
        product.title
    );
  }


  return (
      <div className="productDetail-main"  style={{ opacity: imageLoaded ? 1 : 0 }}>
      
        <div className="product-details-img-box">
          <img
            src={`${S3_BUCKET_IMAGES_URL}${product.image}`}
            alt={product.title}
            className="product-gallery"
          onLoad={handleImageLoad}

          />
        </div>

        <div className="productDetails" style={{ opacity: imageLoaded ? 1 : 0 }}>
          <div className="product-page-parameters">
            <div className="details-container">
                  <p>{product.title}</p>
                  <p className="taxes-duties">
                  {translation.t("taxes_duties")}
                  </p>
                  <br/>
                  <p className="product_price">{product.price}</p>
            </div>
            <br />
            <div className="size-and-cart-box">
                
              
            <span className="size-error" style={{ display: sizeError ? 'block' : 'none' }}>{translation.t("select_size")}</span>
                <span className="added-to-cart" style={{ display: addedToCart ? 'block' : 'none' }}>{translation.t("size")} <strong>[{selectedSize}]</strong> {translation.t("added_to_bag")}</span>
                <span className="size-error" style={{ display: quantityError ? 'block' : 'none' }}>{translation.t("out_of_stock")}</span>
              <div className="selectSize-box">
               
                <select
                  id="size"
                  onChange={(e) => {
                    setSelectedSize(e.target.value);
                    setSizeError(false); 
                    setQuantityError(false)
                    setAddedToCart(false)
                  }}
                  style={{border: sizeError ? '2px solid #c40000' : '1px solid #000'}}
                >
                  <option value="">{translation.t("choose_size")}</option>
                  {product.sizes.map((size) => (
                    <option
                      disabled={disabledSize(size)}
                      key={size}
                      value={size}
                    >
                      {size}
                    </option>
                  ))}
                </select>
                {/* <span style={{fontSize:"23px",position:"absolute", right:"10px"}}>&#8964;</span> */}
                <span className="arrow-down"></span>
              </div>
              <button
                onClick={handleAddToCart}
                className="addToCartBtn"
                // disabled={handleAddToCartDisabled(product, selectedSize)}
              >
                {translation.t("add_to_cart")}
              </button>


              <span key={animationKey} className={`item-added-animation ${animationTriggered ? 'item-added-animation_active' : ''}`}><span>&uarr;</span></span>


             
            </div>

            <br/>
            <div className="details-container">
            <div className="product-all-details">
                    <div>
                    <button className="toggleProductDescription details_toggles" onClick={toggleProductDescription}>{isProductDescriptionVisible ? <img src="/images/minimize_ico.svg" alt="=" />  : <img src="/images/expand_ico.svg" alt="+" />} <p style={{ fontWeight: isProductDescriptionVisible ? 700 : 400, textDecoration: isProductDescriptionVisible ? 'underline' : '' }}>{translation.t("details")}</p></button>
                    
            </div>

            <div>
                    <button className="toggleProductDescription details_toggles" onClick={toggleSizeGuide}>{isSizeGuideVisible ? <img src="/images/minimize_ico.svg" alt="=" />  : <img src="/images/expand_ico.svg" alt="+" />}  <p style={{ fontWeight: isSizeGuideVisible ? 700 : 400, textDecoration: isSizeGuideVisible ? 'underline' : '' }}>{translation.t("size_guide")}</p>
</button>
                    
            </div>
              </div>
                   
              <div className="product-all-details-box">
              <div className="product-fabric-details">
                    {isProductDescriptionVisible && (
            <div
            dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
            )}
            </div>

            {isSizeGuideVisible && (
            

<div
dangerouslySetInnerHTML={{ __html: product.size_guide }}
></div>
            )}
              </div>

              <br />
              <div>
                <p className="questions_info">
                  {translation.t("shipping_and_returns_content")}{" "}
                  <span>support@bluniblu.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <CartDrawer
          anchor="right"
          open={openCartDrawer}
          translation={translation}
        />
      </div>
  );
};

export default ProductDetail;
