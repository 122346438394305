import React, { useContext } from "react";
import "./terms.css";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { openCartDrawer } = useContext(AppContext);
  const translation = useTranslation();
  return (
    <div>
      <div className="container-terms">
        <div className="content-terms">
        <h2>TERMS AND CONDITIONS</h2>
          <p>
            <strong>1. Introduction</strong>
            <br />
            By accessing or using Bluniblu website (the "Site") and purchasing
            products from us, you agree to be bound by these Terms and
            Conditions ("Terms"). Please read these Terms carefully before using
            our Site.
            <br />
            <br />
            <strong>2. Scope of Service</strong>
            <br />
            Bluniblu offers an online platform for the sale of clothing and
            related accessories. Our services are available to individuals who
            are at least <strong>13 years old or older</strong>, provided they
            have the consent of a parent or guardian if they are under the age
            of majority in their jurisdiction.
            <br />
            <br />
            <strong>3. Product Information</strong>
            <br />
            We strive to provide accurate descriptions and images of our
            products on the Site. However, we do not guarantee that colors,
            dimensions, or other product details will be completely accurate due
            to variations in screen resolutions and manufacturing processes.
            <br />
            <br />
            <strong>4. Pricing and Payment</strong>
            <br />
            All prices displayed on the Site are <strong>exclusive</strong> of
            applicable taxes unless otherwise stated. Taxes will be calculated
            and added to your order total during the checkout process. You are
            responsible for paying any applicable taxes imposed by your
            jurisdiction in relation to your purchase.
            <br />
            <br />
            <strong>5. Order Fulfillment</strong>
            <br />
            Upon placing an order, you will receive an email confirmation with
            details of your purchase. Orders are subject to availability, and we
            reserve the right to cancel or modify orders if necessary. We will
            make reasonable efforts to fulfill orders promptly, but we do not
            guarantee specific delivery times.
            <br />
            <br />
            <strong>6. Shipping and Delivery</strong>
            <br />
            We offer shipping to locations as indicated on the Site. Shipping
            costs and estimated delivery times are provided during the checkout
            process. While we strive to deliver orders in a timely manner, we
            are not responsible for delays caused by carriers or unforeseen
            circumstances.
            <br />
            <br />
            <strong>7. Returns and Exchanges</strong>
            <br />
            We want you to be satisfied with your purchase from Bluniblu. If for
            any reason you are not completely happy with your order, you may
            return eligible items within 10 days of receipt for a refund or
            exchange, subject to our Returns Policy.
            <br />
            <br />
            <strong>8. Intellectual Property</strong>
            <br />
            All content on the Site, including but not limited to text, images,
            logos, and graphics, is the property of Bluniblu or its licensors
            and is protected by copyright and other intellectual property laws.
            You may not use, reproduce, or distribute any content from the Site
            without our prior written consent.
            <br />
            <br />
            <strong>9. Privacy Policy</strong>
            <br />
            Our Privacy Policy governs the collection, use, and disclosure of
            personal information provided by users of the Site. By using our
            services, you consent to the practices described in our Privacy
            Policy.
            <br />
            <br />
            <strong>10. Limitation of Liability</strong>
            <br />
            In no event shall Bluniblu, its affiliates, or their respective
            directors, officers, employees, or agents be liable for any
            indirect, incidental, special, or consequential damages arising out
            of or in connection with your use of the Site or the purchase of
            products from us.
            <br />
            <br />
            <strong>11. Governing Law</strong>
            <br />
            These Terms and any disputes arising from or relating to them shall
            be governed by and construed in accordance with the laws of Republic
            of Kosovo, without regard to its conflict of law principles.
            <br />
            <br />
            <strong>12. Changes to Terms</strong>
            <br />
            We reserve the right to update or modify these Terms at any time
            without prior notice. Any changes will be effective immediately upon
            posting on the Site. It is your responsibility to review these Terms
            periodically for updates.
            <br />
            <br />
            <strong>13. Contact Us</strong>
            <br />
            If you have any questions or concerns about these Terms or our
            services, please contact us at <strong>privacy@bluniblu.com</strong>
            .
            <br />
            <br />
            By using the Bluniblu website, you agree to abide by these Terms and
            Conditions.
          </p>
        </div>
      </div>
      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};

export default Terms;
