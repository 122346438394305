import React, { useState } from "react";

// import { Link } from "react-router-dom";
// import Loader from "./pages/loader/Loader";

const Product = ({ title, price, image }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={`product ${imageLoaded ? "product_active" : "" }`}>
      {/* {!imageLoaded && (
        <div className="loader-all">
          <Loader />
        </div>
      )} */}
      <a href={`/shop/product/${title}`}>
        <img
          src={image}
          alt={title}
          className="product-image"
          
          onLoad={handleImageLoad}
        />
        <div className="product-grid-details-box">
          <p style={{ fontSize: "12px" }}>{title}</p>
          <p style={{ fontSize: "12px" }}>{price}</p>
        </div>
      </a>
    </div>
  );
};

export default Product;
