import React, { useContext } from "react";
import "./terms.css";
import { CartDrawer } from "../../components/CartDrawer/CartDrawer";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";

const Returns = () => {
  const { openCartDrawer } = useContext(AppContext)
  const translation = useTranslation();
  return (
    <div>
      <div className="container-terms">
        <div className="content-terms">
        <h2>REFUND POLICY</h2>
          <p>
            <strong>1. Eligibility for Refunds</strong>
            <br />
            We want you to be satisfied with your purchase from Bluniblu. If for
            any reason you are not completely happy with your order, you may be
            eligible for a refund or exchange. To qualify for a refund or
            exchange, items must be returned in their original condition within
            10 days of receipt.
            <br />
            <br />
            <strong>2. Cancellation Policy</strong>
            <br />
            You may cancel your order within the first <strong>
              24 hours
            </strong>{" "}
            of purchase for a full refund. After this time frame, cancellations
            will be subject to our standard refund policy.
            <br />
            <br />
            <strong>3. How to Initiate a Refund or Exchange</strong>
            <br />
            To request a refund or exchange, please contact our customer service
            team at <strong>support@bluniblu.com</strong> within the specified timeframe. You will be
            provided with instructions on how to return the item(s) and complete
            the refund or exchange process.
            <br /> 
            <br />  
            <i>We would like to note that Bluniblu does not cover the expenses incurred by customers for returning items unless the return is due to a defect or damage upon receipt.</i>
            <br />
            <br />
            <strong>4. Refund Processing</strong>
            <br />
            Once your returned item(s) are received and inspected, we will send
            you an email notification regarding the status of your refund. If
            approved, refunds will be processed to the original method of
            payment within a reasonable timeframe.
            <br />
            <br />
            <strong>5. Exclusions</strong>
            <br />
            Please note that certain items, such as personalized or custom-made
            products, may not be eligible for refunds or exchanges unless they
            are defective or damaged upon receipt.
            <br />
            <br />
            <strong>6. Contact Us</strong>
            <br />
            If you have any questions or concerns about our refund policy,
            please contact us at <strong>support@bluniblu.com</strong>.
            <br />
            <br />
          </p>
        </div>
      </div>
      <CartDrawer
        anchor="right"
        open={openCartDrawer}
        translation={translation}
      />
    </div>
  );
};

export default Returns;
