import React, { useContext, useEffect } from "react";
import Product from "./Product";
import "./ProductGrid.css";
import { CartDrawer } from "./components/CartDrawer/CartDrawer";
import { AppContext } from "./AppContext";
import { S3_BUCKET_IMAGES_URL } from "./utils/constants";
import { useProducts } from "./hooks/useProducts";
import Loader from "./pages/loader/Loader";

const ProductGrid = ({ translation }) => {
  const { productsList: products, isLoading } = useProducts();
  const { setOpenCartDrawer, openCartDrawer } = useContext(AppContext);

  useEffect(() => {
    setOpenCartDrawer(false);
  }, [setOpenCartDrawer]);

  if (isLoading) {
    return <div className="loader-container"><Loader /></div>;
  }

  return (
    <div className="product-grid">
      {products.map((product) => (
        <Product
          key={product.id}
          title={product.title}
          image={`${S3_BUCKET_IMAGES_URL}/product-images/small/${product.image.split("/product-images/")[1]}`}
          price={product.price}
        />
      ))}
      <CartDrawer anchor="right" open={openCartDrawer} translation={translation} />
    </div>
  );
};

export default ProductGrid;
